<template>
  <!-- <li v-if="this.value1 !== undefined && this.value2 !== undefined" class="flex justify-content-between align-items-center py-1">
      <span :class="'flex justify-content-center person-tag fg-lightGray p-1 text-sm ' + this.col1" v-tooltip="value1.label"><i :class="'fi ' + icon1 + ' icon-inline text-base mr-1 fg-amber '"></i>{{ value1.value + unit_1 }}</span>
      <div class="hidden md:flex justify-content-center person-item m-0">
              <small class="muted-text">{{ title }}</small>
          </div>
      <span :class="'flex justify-content-center person-tag fg-lightGray p-1 text-sm ' + this.col2" v-tooltip="value2.label"><i :class="'fi ' + icon2 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value2.value + unit_2 }}</span>
  </li> -->
  <li v-if="this.value1 !== undefined && this.value2 !== undefined" class="flex justify-content-start align-items-center py-1">
    <div class="hidden sm:flex justify-content-center person-item m-0">
        <small class="">{{ titleStr }}</small>
    </div>
    <span :class="'person-tag ml-auto fg-lightGray p-1 line-height-3 text-sm cursor-pointer ' + this.col1" v-tooltip="`<span class='fg-lightYellow'>${value1.description}</span><hr class='mt-1 mb-1' />${timestamp_1}`" @click="showChart('value1')">
      <i :class="'fi ' + icon_1 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value_1.value }}<span class="text-xs">{{ unit_1 }}</span>
    </span>
    <span :class="'person-tag ml-1 fg-lightGray p-1 line-height-3 text-sm cursor-pointer ' + this.col2" v-tooltip="`<span class='fg-lightYellow'>${value2.description}</span><hr class='mt-1 mb-1' />${timestamp_2}`" @click="showChart('value2')">
      <i :class="'fi ' + icon_2 + ' icon-inline text-base mr-1 fg-amber'"></i>{{ value_2.value }}<span class="text-xs">{{ unit_2 }}</span>
    </span>
  </li>

  <dialog-chart-single :icon="icon_1" :node="value1" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
  <dialog-chart-single :icon="icon_2" :node="value2" v-bind:show="showChart2" @chart-close="showChart2 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import { formatDateGui } from "@/helpers";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetRowValues2",
  setup() {},
  components: {
   dialogChartSingle
  },
  props: {
    title: {
      type: String,
      required: false
    },
    value1: {
      type: null,
      required: false
    },
    icon1: {
      type: String,
      required: false
    },
    color1: {
      type: String,
      required: false
    },
    value2: {
      type: null,
      required: false
    },
    icon2: {
      type: String,
      required: false
    },
    color2: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      showChart1: false,
      showChart2: false,
    }
  },
  watch: {

  },
  computed: {
    titleStr: function () {
      if (this.title) return this.title;
      return `${this.value1.label} / ${this.value2.label}`;
    },
    col1: function () {
      if (this.color1 !== undefined) {
        return this.color1;
      } else {
        return 'bg-darkOlive';
      }
    },
    col2: function () {
      if (this.color2 !== undefined) {
        return this.color2;
      } else {
        return 'bg-darkOlive';
      }
    },
    value_1: function () {
      return this.parseValue(this.value1);
    },
    value_2: function () {
      return this.parseValue(this.value2);
    },
    unit_1: function () {
      return this.value1.unitStr;
    },
    unit_2: function () {
      return this.value2.unitStr;
    },
    timestamp_1: function () {
      if (this.value1.timestamp) {
        return formatDateGui(this.value1.timestamp);
      }
      return '';
    },
    timestamp_2: function () {
      if (this.value1.timestamp) {
        return formatDateGui(this.value2.timestamp);
      }
      return '';
    },
    icon_1: function () {
      if (this.icon1) {
        return this.icon1;
      }
      return this.value1.icon;
    },
    icon_2: function () {
      if (this.icon2) {
        return this.icon2;
      }
      return this.value2.icon;
    },
  },
  mounted() {
  },
  methods: {
    parseValue(val) {
      const value = { ...val };
      if (value.unit === '#BOOL') {
        if (value.value === true) value.value = '🟢Ein';
        else value.value = '🔴Aus';
      }
      return value;
    },
    showChart(value) {
      if (value === 'value1') {
        this.showChart1 = true;
      }
      if (value === 'value2') this.showChart2 = true;
    },
  },
});
</script>

<style lang="scss" scoped>

</style>